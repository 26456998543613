<!--
 * @Author: DY
 * @Date: 2020-08-31 20:25:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-10-20 17:35:43
 * @Describe:
-->
<template>
    <div
        class="lifeCycle"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <div class="search">
            <ul>
                <li>
                    <el-input
                        clearable
                        v-model="value"
                        placeholder="请输入内容"
                        class="input-with-select"
                    >
                        <el-select v-model="select" slot="prepend" placeholder="请选择">
                            <el-option label="工程名称" value="1"></el-option>
                            <el-option label="建设单位" value="2"></el-option>
                            <el-option label="工程位置" value="3"></el-option>
                        </el-select>
                        <el-button slot="append" icon="el-icon-search" @click="queryMapData"></el-button>
                    </el-input>
                </li>
                <li class="regionList">
                    <h5 class="lineHeight">
                        区域：
                    </h5>
                    <el-select
                        clearable
                        v-model="selectArea"
                        placeholder="请选择"
                        @change="selectAreaList"
                    >
                        <template v-for="(item, index) in areaData">
                            <el-option
                                :key="`area_${index}`"
                                :label="item.org_name"
                                :value="item.id"
                            >
                            </el-option>
                        </template>
                    </el-select>
                    <el-select v-model="selectEnterprise" placeholder="请选择">
                        <template v-for="(item, index) in enterpriseData">
                            <el-option
                                :key="`enterprise_${index}`"
                                :label="item.org_name"
                                :value="item.id"
                            >
                            </el-option>
                        </template>
                    </el-select>
                </li>
                <li>
                    <h5>工程：</h5>
                    <el-radio-group v-model="keyProjects">
                        <el-radio label="">
                            全部
                        </el-radio>
                        <el-radio label="1">
                            大项目
                        </el-radio>
                        <el-radio label="2">
                            一般工程
                        </el-radio>
                    </el-radio-group>
                </li>
                <li>
                    <h5>类型：</h5>
                    <el-checkbox-group v-model="projectType">
                        <template v-for="(item,index) in projectTypeData">
                            <el-checkbox :key="index" :class="item.class" :label="item.name"></el-checkbox>
                        </template>
                    </el-checkbox-group>
                </li>
            </ul>
        </div>
        <el-amap
            vid="amap"
            :zoom="zoom"
            :center="mapCenter"
            :amap-manager="amapManager"
            :events="mapEvents"
        >
            <!-- 工程地址 -->
            <el-amap-marker
                v-for="(marker, index) in showMarkers"
                :key="index"
                :position="marker.position"
                :content="marker.content"
                :events="marker.events"
            ></el-amap-marker>
            <!-- 信息窗体 -->
            <el-amap-info-window
                :position="engineeringInfo.position"
                :visible="engineeringInfo.visible"
                :offset="engineeringInfo.offset"
                :events="engineeringInfo.events"
                :close-when-click-map="true"
            >
                <div class="engineering_Info">
                    <ul>
                        <li>
                            <p class="iconfont icongongdi">
                                工程名称：{{ engineeringInfoData.engineering_name }}
                            </p>
                        </li>
                        <li>
                            <p class="iconfont iconqiye">
                                建设单位：{{ engineeringInfoData.client_name }}
                            </p>
                        </li>
                        <li>
                            <p class="iconfont iconlocation1">
                                工程位置：{{ engineeringInfoData.engineering_location }}
                            </p>
                        </li>
                        <li>
                            <p class="iconfont iconqiye">
                                供货企业：{{ engineeringInfoData.company_name }}
                            </p>
                        </li>
                        <li>
                            <p class="iconfont iconanalysis-chart">
                                工程类型：{{ engineeringInfoData.engineering_type_name }}
                            </p>
                        </li>
                        <li>
                            <p class="iconfont iconlifang">
                                预计方量：{{ engineeringInfoData.projected_capacity }}
                            </p>
                            <p>已用方量：{{ engineeringInfoData.used_capacity }}</p>
                        </li>
                        <li>
                            <p class="iconfont iconshijianxiao">
                                首次供应时间：{{ engineeringInfoData.supply_time_first }}
                            </p>
                        </li>
                    </ul>
                    <div class="btns">
                        <el-button
                            type="primary"
                            @click="openProjectLifeCycle()"
                            v-if="engineeringInfoData.type == 1"
                        >
                            项目生命周期
                        </el-button>
                        <template v-if="engineeringInfoData.type == 2 && (engineeringInfoData.contract_numbers || []).length >0">
                            <el-button
                                type="primary"
                                v-if="engineeringInfoData.contract_numbers.length == 1"
                                @click="openContractLifeCycle(engineeringInfoData.contract_numbers[0], null)"
                            >
                                <span>合同生命周期</span>
                            </el-button>
                            <el-badge v-else :value="engineeringInfoData.contract_numbers.length" class="item">
                                <el-button
                                    type="primary"
                                    @click="contractSelection = true"
                                >
                                    <span>合同生命周期</span>
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                            </el-badge>
                        </template>
                    </div>
                    <div class="contractSelection iconfont iconshangjiantou" v-if="contractSelection">
                        <p @click="contractSelection = false">
                            ×
                        </p>
                        <ul>
                            <template v-for="(item,index) in engineeringInfoData.contract_numbers">
                                <li
                                    :key="index"
                                    @click="openContractLifeCycle(item, index)"
                                    :class="{'active': contractIndex == index}"
                                >
                                    {{ item }}
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </el-amap-info-window>
        </el-amap>
    </div>
</template>
<script>
import remoteLoad from '@/assets/map/remoteLoad.js';
import VueAMap from 'vue-amap';
const amapManager = new VueAMap.AMapManager();
VueAMap.initAMapApiLoader({
    key: 'b4007ecc143884e79eab2c515ede82cf',
    plugin: [
        'AMap.AMapManager',
        'AMap.Marker',
        'AMap.GeometryUtil',
    ],
    v: '1.4.4',
});

export default {
    // 工程生命周期
    name: 'engineering-life-cycle',
    components: {},
    props: {},
    data() {
        const _that = this;
        return {
            loading: false,
            // 搜索输入框
            value: '',
            // 下拉
            select: '1',
            // 片区数据
            areaData: [],
            selectArea: '',
            // 企业数据
            enterpriseData: [],
            selectEnterprise: '',
            // 重点工程
            keyProjects: '',
            // 工程类型待选数据
            projectTypeData: [],
            // 工程类型
            projectType: [],
            zoom: 6,
            // 地图中心点坐标值
            mapCenter: [117.120098, 36.6512],
            // 地图管理对象
            amapManager,
            mapObj: null,
            AMap: null,
            mapEvents: {
                init(AMapObj) {
                    _that.mapObj = AMapObj;
                    AMapObj.setMapStyle('amap://styles/darkblue');
                    AMapObj.on('moveend', () => {
                        _that.newmoveend();
                    });
                    _that.getMapData();
                },
            },

            // 地图企业数据
            mapEngineeringData: [],
            // 地图工程数据
            mapEnterpriseData: [],

            // 地图显示Marker
            showMarkers: [],
            // 工程信息
            engineeringInfo: {
                position: [117.120098, 36.6512],
                visible: false,
                offset: [5, -30],
                events: {},
            },
            // 工程信息数据
            engineeringInfoData: {},
            // 当前工程编号
            engineering_number: '',
            // 租户id
            zu_h_id: '',
            // 选择合同层状态
            contractSelection: false,
            // 当前选中合同下标
            contractIndex: null,
        };
    },
    async created() {
        this.loading = true;
        if (!window.AMap) {
            await remoteLoad(
                'http://webapi.amap.com/maps?v=1.4.15&key=b4007ecc143884e79eab2c515ede82cf'
            );
        }
        this.AMap = window.AMap;
        // 获取片区数据
        this.areaData = await this.getAreaData();
        // 工程类型待选数据
        const typeData = await this.getProjectTypeData();
        // 工程类型数据处理
        this.projectTypeDataProcessing(typeData);
    },
    mounted() {},
    activated() {},
    update() {},
    beforeRouteUpdate() {},
    methods: {
        // 工程类型数据处理
        projectTypeDataProcessing(typeData) {
            (typeData || []).forEach(item => {
                if (item.code === '103-DL0') {// 电力
                    item.color = '#FF3AA0';
                    item.class = 'type1';
                } else if (item.code === '103-FJ0') {// 房建
                    item.color = '#FF8C40';
                    item.class = 'type2';
                } else if (item.code === '103-GD0') {// 轨道
                    item.color = '#FAAC15';
                    item.class = 'type3';
                } else if (item.code === '103-GL1') {// 管廊
                    item.color = '#1890FF';
                    item.class = 'type4';
                } else if (item.code === '103-GL2') {// 公路
                    item.color = '#FFEC3D';
                    item.class = 'type5';
                } else if (item.code === '103-HY0') {// 海洋
                    item.color = '#39CFFF';
                    item.class = 'type6';
                } else if (item.code === '103-QT0') {// 其他
                    item.color = '#53C419';
                    item.class = 'type7';
                } else if (item.code === '103-SD0') {// 隧道
                    item.color = '#12D8F0';
                    item.class = 'type8';
                } else if (item.code === '103-SL0') {// 水利
                    item.color = '#B37FEB';
                    item.class = 'type9';
                } else if (item.code === '103-SZ0') {// 市政
                    item.color = '#00C89B';
                    item.class = 'type10';
                } else if (item.code === '103-TL0') {// 铁路
                    item.color = '#FF3A3A';
                    item.class = 'type11';
                }
                this.projectTypeData = typeData;
            });
        },

        // 获取地图数据
        async getMapData() {
            const requestData = this.processingQueryCriteria();
            // 企业数据
            this.mapEngineeringData = await this.getMapEnterpriseData(requestData);
            // 工程数据
            this.mapEnterpriseData = await this.getEngineeringData(requestData);
            // 加载企业Marker
            this.loadEngineeringMarker(this.mapEngineeringData);
            this.loading = false;
        },

        // 加载企业Marker
        loadEngineeringMarker(markerData) {
            this.showMarkers = [];
            (markerData || []).forEach(item => {
                if (item.longitude && item.latitude) {
                    const markerContent = `<div class="enterpriseMarker"><p>${item.count}</p><span>${item.tenant_name}</span></div>`;
                    this.showMarkers.push({
                        position: [item.longitude, item.latitude],
                        content: markerContent,
                    });
                }
            });
            this.$forceUpdate();
        },

        // 加载工程Marker
        loadEnterpriseMarker(markerData) {
            this.showMarkers = [];
            (markerData || []).forEach(item => {
                if (item.x_coordinate_point && item.y_coordinate_point) {
                    const lnglat = [item.x_coordinate_point, item.y_coordinate_point];
                    const isVisual = this.logMapBounds(lnglat.toString());
                    if (isVisual) {
                        let newColor = '';
                        this.projectTypeData.forEach(k => {
                            if (item.engineering_type === k.code) {
                                newColor = k.color;
                            }
                        });
                        let markerContent = '';
                        if (item.type === 1) {
                            markerContent = `<div class="mapSign">
                                    <div class="mapIcon iconfont iconposition" style="color:${newColor};"></div>
                                    <p class="signName" style="color:${newColor};">${item.engineering_name}</p>
                                    </div>`;
                        } else if (item.type === 2) {
                            markerContent = `<div class="mapSign">
                                    <div class="mapIcon iconfont iconqizi" style="color:${newColor};"></div>
                                    <p class="signName" style="color:${newColor};">${item.engineering_name}</p>
                                    </div>`;
                        }
                        this.showMarkers.push({
                            position: [item.x_coordinate_point, item.y_coordinate_point],
                            content: markerContent,
                            events: {
                                click: () => {
                                    this.viewDetails(item);
                                },
                            },
                        });
                    }
                }
            });
            this.$forceUpdate();
        },

        // 区域级联选择
        async selectAreaList(val) {
            this.selectEnterprise = '';
            this.enterpriseData = await this.getEnterpriseData(val);
        },

        /** 规划处地图的可视范围，且判断经纬度是否在当前可视区域
        * center是经纬度字符串，用","拼接
        * @param {string} center
        * @returns {Boolean}
        **/
        logMapBounds(center) {
            const point = center.split(',');
            const bounds = this.mapObj.getBounds();
            const NorthEast = bounds.getNorthEast();
            const SouthWest = bounds.getSouthWest();
            const SouthEast = [NorthEast.lng, SouthWest.lat];
            const NorthWest = [SouthWest.lng, NorthEast.lat];
            const path = [[NorthEast.lng, NorthEast.lat], SouthEast, [SouthWest.lng, SouthWest.lat], NorthWest];
            return this.AMap.GeometryUtil.isPointInRing(point, path);
        },

        // 处理查询条件
        processingQueryCriteria() {
            // 当前工程编号
            this.engineering_number = '';
            // 租户id
            this.zu_h_id = '';
            const newProjectType = [];
            this.projectTypeData.forEach(item => {
                this.projectType.forEach(k => {
                    if (item.name === k) {
                        newProjectType.push(item.code);
                    }
                });
            });
            const requestData = {
                pian_q_id: this.selectArea,
                qi_y_id: this.selectEnterprise,
                engineering_type: newProjectType,
                is_project: this.keyProjects,
            };
            if (this.select === '1') {
                requestData.engineering_name = this.value;
            } else if (this.select === '2') {
                requestData.engineering_location = this.value;
            } else {
                requestData.client_name = this.value;
            }
            return requestData;
        },


        // 地图拖拽
        newmoveend() {
            const zoom = this.mapObj.getZoom();
            if (zoom >= 12) {
                this.loadEnterpriseMarker(this.mapEnterpriseData);
            } else {
                this.engineeringInfo.visible = false;
                this.loadEngineeringMarker(this.mapEngineeringData);
            }
        },

        // 查询
        async queryMapData() {
            this.loading = true;
            const requestData = this.processingQueryCriteria();
            // 企业数据
            this.mapEngineeringData = await this.getMapEnterpriseData(requestData);
            // 工程数据
            this.mapEnterpriseData = await this.getEngineeringData(requestData);
            if (this.value === '') {
                this.mapObj.setZoomAndCenter(6, [117.120098, 36.6512]);
                this.loadEngineeringMarker(this.mapEngineeringData);
            } else {
                const lnglat = [this.mapEnterpriseData[0].x_coordinate_point, this.mapEnterpriseData[0].y_coordinate_point];
                this.mapObj.setZoomAndCenter(11, lnglat);
            }
            this.loading = false;
        },

        // 查看工程详情
        async viewDetails(data) {
            this.loading = true;
            this.contractIndex = null;
            this.contractSelection = false;
            this.engineeringInfo.visible = false;
            const getProjectDetails = {
                type: data.type,
                zu_h_id: data.zu_h_id,
                engineering_number: data.engineering_number,
            };
            this.engineeringInfoData = {};
            // 拉取工程详情数据
            const row = await this.getProjectDetails(getProjectDetails);
            if (row) {
                if ((row.contract_numbers || '') !== '') {
                    row.contract_numbers = row.contract_numbers.split(',');
                }
                this.engineeringInfoData = row;
            } else {
                this.$message({
                    message: '暂无工程详情数据！',
                    type: 'warning',
                });
            }
            this.loading = false;
            this.$nextTick(() => {
                // 工程信息窗体位置
                this.engineeringInfo.position = [data.x_coordinate_point, data.y_coordinate_point];
                this.engineeringInfo.visible = true;
            });
        },
        // 打开项目生命周期
        openProjectLifeCycle() {
            // 当前工程编号
            this.engineering_number = this.engineeringInfoData.engineering_number;
            // 租户id
            this.zu_h_id = this.engineeringInfoData.zu_h_id;

            this.$toast({
                title: false,
                type: 'eject',
                theme: 'black',
                width: '16.8rem',
                height: '90%',
                style: {},
                t_url: 'lifeCycle/engineeringLifeCycle',
                extr: {
                    code: {
                        engineeringNumber: this.engineering_number,
                        zuHId: this.zu_h_id,
                    },
                },
            });
        },
        // 打开合同生命周期
        openContractLifeCycle(id, index) {
            this.contractIndex = index;
            this.$toast({
                title: false,
                type: 'eject',
                theme: 'black',
                width: '16.8rem',
                height: '90%',
                style: {},
                t_url: 'lifeCycle/contractLifeCycle',
                extr: {
                    code: {
                        contract_number: id,
                        zuHId: this.engineeringInfoData.zu_h_id,
                    },
                },
            });
        },

        // 获取片区数据
        getAreaData() {
            const path = '/interfaceApi/report/report/get_area?id=OT000002';
            return this.$axios.$get(path, { defEx: true });
        },

        // 获取片区下企业数据
        getEnterpriseData(treeid) {
            const path = `/interfaceApi/report/report/get_tenant?id=${treeid}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 获取工程类型数据
        getProjectTypeData() {
            const path = '/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=103&type=1';
            return this.$axios.$get(path, { defEx: true });
        },

        // 获取地图企业数据
        getMapEnterpriseData(requestData) {
            const path = '/interfaceApi/sale/engineering_life_cycle/engineering_company';
            return this.$axios.$post(path, requestData, { defEx: true });
        },

        // 获取工程数据
        getEngineeringData(requestData) {
            const path = '/interfaceApi/sale/engineering_life_cycle/engineering_list';
            return this.$axios.$post(path, requestData, { defEx: true });
        },

        // 获取工程详情数据
        getProjectDetails(requestData) {
            // eslint-disable-next-line max-len
            const path = `/interfaceApi/sale/engineering_life_cycle/engineering_map_details?engineering_number=${requestData.engineering_number}&type=${requestData.type}&zu_h_id=${requestData.zu_h_id}`;
            return this.$axios.$post(path, requestData, { defEx: true });
        },
    },
    filter: {},
    computed: {},
};
</script>

<style lang="stylus">
.lifeCycle
    height 100%
    position relative
    .search
        position absolute
        z-index 9
        top 0.2rem
        left 0.2rem
        width 4.5rem
        height auto
        background #002851
        box-shadow inset 0px 0px 20px 0px rgba(0, 0, 0, 0.5), inset 0px 0px 12px 0px rgba(2, 174, 252, 0.8)
        border-radius 2px
        padding 0.2rem
        padding-bottom 0.1rem
        .input-with-select
            border 2px solid #083262
            border-radius 2px
            position relative
            &:after
                content ''
                height 0.2rem
                width 1px
                background #000409
                position absolute
                top 50%
                left 1.2rem
                margin-top -0.1rem
            .el-input-group__prepend
                width 1.2rem
                overflow hidden
            .el-input__inner
                color rgba(255,255,255,0.6)
            .el-input-group__prepend,
            .el-input__inner,
            .el-input-group__append
                background #041A34
                border none
            .el-select
                .el-input__inner
                    color #1890FF
            .el-input-group__append
                .el-button
                    color #fff
        ul
            li
                display flex
                margin-bottom 0.15rem
                &:last-child
                    margin-bottom 0
                h5
                    font-size 0.18rem
                    line-height 0.18rem
                    text-align right
                    color #1890FF
                    width 0.6rem
                    &.lineHeight
                        line-height 0.36rem
                .el-checkbox
                    margin-right 0.2rem
                    margin-bottom 0.1rem
                .el-radio-group,
                .el-checkbox-group
                    width calc(100% - 0.6rem)
                    .el-radio
                        color rgba(255, 255, 255, 0.5)
                        font-size 0.16rem
                    .type1
                        .el-checkbox__label
                            color #FF3AA0
                        .el-checkbox__input
                            &.is-checked
                                .el-checkbox__inner
                                    background-color #FF3AA0
                                    border-color #FF3AA0
                    .type2
                        .el-checkbox__label
                            color #FF8C40
                        .el-checkbox__input
                            &.is-checked
                                .el-checkbox__inner
                                    background-color #FF8C40
                                    border-color #FF8C40
                    .type3
                        .el-checkbox__label
                            color #FAAC15
                        .el-checkbox__input
                            &.is-checked
                                .el-checkbox__inner
                                    background-color #FAAC15
                                    border-color #FAAC15
                    .type4
                        .el-checkbox__label
                            color #1890FF
                        .el-checkbox__input
                            &.is-checked
                                .el-checkbox__inner
                                    background-color #1890FF
                                    border-color #1890FF
                    .type5
                        .el-checkbox__label
                            color #FFEC3D
                        .el-checkbox__input
                            &.is-checked
                                .el-checkbox__inner
                                    background-color #FFEC3D
                                    border-color #FFEC3D
                    .type6
                        .el-checkbox__label
                            color #39CFFF
                        .el-checkbox__input
                            &.is-checked
                                .el-checkbox__inner
                                    background-color #39CFFF
                                    border-color #39CFFF
                    .type7
                        .el-checkbox__label
                            color #53C419
                        .el-checkbox__input
                            &.is-checked
                                .el-checkbox__inner
                                    background-color #53C419
                                    border-color #53C419
                    .type8
                        .el-checkbox__label
                            color #12D8F0
                        .el-checkbox__input
                            &.is-checked
                                .el-checkbox__inner
                                    background-color #12D8F0
                                    border-color #12D8F0
                    .type9
                        .el-checkbox__label
                            color #B37FEB
                        .el-checkbox__input
                            &.is-checked
                                .el-checkbox__inner
                                    background-color #B37FEB
                                    border-color #B37FEB
                    .type10
                        .el-checkbox__label
                            color #00C89B
                        .el-checkbox__input
                            &.is-checked
                                .el-checkbox__inner
                                    background-color #00C89B
                                    border-color #00C89B
                    .type11
                        .el-checkbox__label
                            color #FF3A3A
                        .el-checkbox__input
                            &.is-checked
                                .el-checkbox__inner
                                    background-color #FF3A3A
                                    border-color #FF3A3A
                &.regionList
                    .el-select
                        width calc((100% - 0.7rem)/2)
                        line-height: 0.36rem
                        border 2px solid #083262
                        border-radius 2px
                        margin-right 0.1rem
                        .el-input__inner
                            color rgba(255,255,255,0.6)
                        .el-input-group__prepend,
                        .el-input__inner,
                        .el-input-group__append
                            background #041A34
                            border none
                        &:last-child
                            margin-right 0
    .mapSign
        // text-align center
        .mapIcon
            // display inline-block
            width 0.24rem
            height 0.24rem
            font-size 0.24rem
        .signName
            // display none
            font-size 0.12rem
            width 1rem
            margin 0
            margin-top 0.05rem
            transform translateX(-40%)
            text-align center
    .amap-logo
        display none
        opacity 0 !important
    .amap-copyright
        opacity 0
    .amap-info
        .amap-info-contentContainer
            .amap-info-content
                width 4.5rem
                height auto
                padding 0.2rem
                border-radius 2px
                position static
                background rgba(0, 22, 54, 0.8)
                box-shadow inset 1px 1px 20px 0px rgba(2, 174, 252, 0.8)
            .amap-info-sharp
                border-top-color rgba(2, 174, 252, 0.4)
            .amap-info-close
                top 0.1rem
                right 0.1rem !important
            .engineering_Info
                width 100%
                height 100%
                >ul
                    >li
                        font-size 0.16rem
                        line-height 0.16rem
                        color #24CAFC
                        margin-bottom 0.2rem
                        display flex
                        &:last-child
                            margin-bottom 0
                    p
                        flex 1
                        white-space nowrap
                        text-overflow ellipsis
                        overflow hidden
                .btns
                    margin-top 0.2rem
                    text-align center
                    .el-button
                        height 0.4rem
                        border none
                        font-size 0.16rem
                        color #fff
                        background linear-gradient(135deg, #3A96FE 0%, #40B6FF 100%)
                        border-radius 2px
                        cursor pointer
                .contractSelection
                    position absolute
                    top 3.05rem
                    left 50%
                    margin-left -1.1rem
                    width 2rem
                    background #062755
                    border 1px solid #05A9F4
                    border-radius 2px
                    margin-top 0.2rem
                    z-index 2
                    &:before
                        position absolute
                        top -0.14rem
                        left 80%
                        font-size 0.16rem
                        color #05A9F4
                    p
                        width 0.2rem
                        height 0.2rem
                        font-size 0.18rem
                        text-align center
                        line-height 0.2rem
                        color #fff
                        cursor pointer
                        position absolute
                        overflow hidden
                        right 0
                        top 0
                    ul
                        width 100%
                        li
                            font-size 0.16rem
                            line-height 0.4rem
                            padding-left 0.1rem
                            color #FFFFFF
                            margin-bottom 0
                            border-bottom 1px solid #05A9F4
                            &:last-child
                                border-bottom none
                            &:hover,
                            &.active
                                border-radius 2px
                                background #031936
                                color #C4FE72
    .enterpriseMarker
        width 0.74rem
        height: 0.74rem
        background linear-gradient(180deg, #FCED7A 0%, #F3BA12 100%)
        box-shadow 1px 1px 4px 0px #00060C
        border 1px solid #F7AF00
        text-align: center
        border-radius 50%
        padding 0.1rem
        p
            font-size 0.24rem
            line-height 0.28rem
            font-family agencyb
            color #3D3938
            white-space: nowrap
        span
            font-size 0.14rem
            line-height 0.16rem
            color #3D3938
            white-space: nowrap
    .el-dialog__wrapper
        height 100%
        position absolute !important
        z-index 2 !important
        .el-dialog
            width 16.8rem !important
            height 90%
            position absolute
            top 50%
            left 50%
            margin 0 !important
            transform translate(-50%, -50%)
            background url('images/bg2.png') #032041
            .el-dialog__header
                padding 0
                height 0.86rem
                background url(images/light.png) center bottom no-repeat
                .el-dialog__title
                    font-size 0.2rem
                    font-family Source Han Sans CN
                    font-weight bold
                    color rgba(255, 255, 255, 1)
                    line-height 0.56rem
                    background linear-gradient(0deg, rgba(108, 151, 216, 1) 0%, rgba(255, 255, 255, 1) 100%)
                    -webkit-background-clip text
                    -webkit-text-fill-color transparent
                .el-dialog__headerbtn
                    font-size 0.22rem
                    color #fff
            .el-dialog__body
                height calc(100% - 0.86rem)
                padding 0
                .dialogContent
                    height 100%
                    overflow-y auto
</style>
